import { MESSAGE_TYPES } from "../../../utils/Enum";

export const IMAGE_TYPES = ["image/jpeg", "image/png"];
export const VIDEO_TYPES = ["video/mp4", "video/3gp"];
export const AUDIO_TYPES = [
  "audio/aac",
  "audio/mp4",
  "audio/mpeg",
  "audio/AMR",
];
export const STICKER_TYPES = ["image/webp"];

export const DOCUMENT_TYPES = [
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export enum ImageState {
  INITIAL = "initial",
  SEARCH = "search",
  GALLERY = "gallery",
  UPLOADED = "uploaded",
}

export const getCorrectMediaFormats = (mediaType: string) => {
  if (mediaType === "video") {
    return VIDEO_TYPES;
  } else if (mediaType === "image") {
    return IMAGE_TYPES;
  } else if (mediaType === "document") {
    return DOCUMENT_TYPES;
  } else if (mediaType === "sticker") {
    return STICKER_TYPES;
  } else if (mediaType === "audio") {
    return AUDIO_TYPES;
  } else {
    return [];
  }
};
export const getUploadedMediaType = (mimeType: string) => {
  if (IMAGE_TYPES?.includes(mimeType)) {
    return "image";
  } else if (VIDEO_TYPES?.includes(mimeType)) {
    return "video";
  } else if (DOCUMENT_TYPES?.includes(mimeType)) {
    return "document";
  } else if (AUDIO_TYPES?.includes(mimeType)) {
    return "audio";
  } else {
    return "document";
  }
};
export const getCorrectMediaSize = (mediaType: string) => {
  if (mediaType === "video") {
    return 16777216;
  } else if (mediaType === "image") {
    return 5242880;
  } else if (mediaType === "document") {
    return 104857600;
  } else if (mediaType === "sticker") {
    return 1048576;
  } else if (mediaType === "audio") {
    return 5242880;
  } else {
    return 1048576;
  }
};

export const getMessageMediaType = (type: string) => {
  if (type === "image") {
    return MESSAGE_TYPES.IMAGE;
  } else if (type === "document") {
    return MESSAGE_TYPES.FILE;
  } else if (type === "video") {
    return MESSAGE_TYPES.VIDEO;
  } else if (type === "audio") {
    return MESSAGE_TYPES.AUDIO;
  } else {
    return MESSAGE_TYPES.FILE;
  }
};

export const sizeInMB = (sizeInBytes: number) => {
  return (sizeInBytes / (1024 * 1024)).toFixed(2);
};
