import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./Store";
import WidgetApp from "./WidgetApp";
//...
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3fee531bf9ad6f4bb041373fefb6dda1@o4507446172188672.ingest.us.sentry.io/4507452062433280",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_SENTRY_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.REACT_APP_SENTRY_DOMAIN as string],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <WidgetApp />
  </Provider>,
  // </React.StrictMode>
);

//* from React 18 Strict Mode renders twice only in develoment mode don't worry in production it will render only once
